import { useInView } from 'react-intersection-observer';
import { HelloWorldContainer } from './HelloWorld.styles';

export const HelloWorld = () => {
  const { ref, inView } = useInView();
  return (
    <HelloWorldContainer ref={ref} startAnimation={inView}>
      <div>
        <ul>
          <h1>Hola</h1>
          <h1>你好</h1>
          <h1>Bonjour</h1>
          <h1>Hello</h1>
        </ul>
      </div>
      <div>
        <ul>
          <h1></h1>
          <h1>World</h1>
        </ul>
      </div>
    </HelloWorldContainer>
  );
};
