import { Colors } from 'src/styles/colors';
import styled, { css } from 'styled-components';

export const HelloWorldContainer = styled.div<{ startAnimation: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  h1 {
    height: 5rem;
    font-family: 'Montserrat';
    font-size: 6rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: -4px;
    margin: 0;
    line-height: 5rem;
  }
  div {
    overflow: hidden;
    height: 5rem;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  div:first-child {
    h1:nth-child(2) {
      font-family: 'Sawarabi Gothic', 'Noto Sans JP';
      font-size: 4.5rem;
      line-height: 5rem;
    }
    h1:nth-child(4):after {
      content: '';
      height: 1rem;
      width: 1rem;
      background-color: ${Colors.BLACK};
      display: inline-block;
      opacity: 0;
      ${({ startAnimation }) =>
        startAnimation &&
        css`
          opacity: 1;
          transition: opacity 1s ease;
          transition-delay: 9.5s;
        `}
    }

    ${({ startAnimation }) =>
      startAnimation &&
      css`
        ul {
          animation-name: flipUp;
          animation-duration: 6s;
          animation-delay: 2s;
          animation-fill-mode: forwards;
        }
      `}
  }
  div:nth-child(2) {
    ul {
      transform: translateY(-50%);
    }

    ${({ startAnimation }) =>
      startAnimation &&
      css`
        ul {
          animation-name: flipDown;
          animation-duration: 1.5s;
          animation-delay: 8s;
          animation-fill-mode: forwards;
        }
      `}
  }

  @media only screen and (max-width: 500px) {
    & {
      flex: 0;
    }
  }

  @keyframes flipUp {
    0% {
      transform: translate3d(0, 0%, 0);
    }
    33% {
      transform: translate3d(0, -25%, 0);
    }
    66% {
      transform: translate3d(0, -50%, 0);
    }
    100% {
      transform: translate3d(0, -75%, 0);
    }
  }
  @keyframes flipDown {
    0% {
      transform: translate3d(0, -50%, 0);
    }
    100% {
      transform: translate3d(0, 0%, 0);
    }
  }
`;
