import { ContactList, ContactListContainer, ContactListItem } from './ContactSources.styles';
import { ReactComponent as MediumIcon } from '../../../assets/svg/medium.svg';
import { ReactComponent as GithubIcon } from '../../../assets/svg/github.svg';
import { ReactComponent as LinkedInIcon } from '../../../assets/svg/linkedin.svg';
import BlogImage from '../../../assets/images/blog.jpg';
import GithubImage from '../../../assets/images/github-retouched.jpg';
import LinkedInImage from '../../../assets/images/linkedin-retouched.jpg';

export const ContactSources = () => {
  return (
    <ContactListContainer>
      <ContactList>
        <ContactListItem>
          <a href="https://medium.com/@eric-sk-cheung" target="_blank" rel="noopener noreferrer">
            <div>
              <p>Blogs</p>
              <MediumIcon />
            </div>
            <img src={`${BlogImage}`} alt="Blog" />
          </a>
        </ContactListItem>
        <ContactListItem>
          <a href="https://github.com/ErockEsquire" target="_blank" rel="noopener noreferrer">
            <div>
              <p>Github</p>
              <GithubIcon />
            </div>
            <img src={`${GithubImage}`} alt="Github" />
          </a>
        </ContactListItem>
        <ContactListItem>
          <a
            href="https://www.linkedin.com/in/eric-sk-cheung/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <p>LinkedIn</p>
              <LinkedInIcon />
            </div>
            <img src={`${LinkedInImage}`} alt="LinkedIn" />
          </a>
        </ContactListItem>
      </ContactList>
    </ContactListContainer>
  );
};
