import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import {
  ContactContainer,
  ContactContent,
  Chevron,
  ChevronContainer,
  ContactHeadingContainer,
  ContactHiddenHeading,
  ContactButton,
} from './Contact.styles';
import { ContactSources } from './ContactSources/ContactSources';
import { ContactForm } from './ContactForm/ContactForm';
import { HelloWorld } from '../Animations/HelloWorld/HelloWorld';

export const Contact = () => {
  const [isTransitioned, setIsTransitioned] = useState(false);
  const { ref, inView } = useInView({ threshold: 1 });
  const navigate = useNavigate();

  const handleSetTransitioned = () => {
    if (window.innerWidth < 500) return;
    setIsTransitioned(!isTransitioned);
  };

  useEffect(() => {
    if (window.innerWidth < 500) {
      setIsTransitioned(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (inView) {
      navigate('/#contact', { replace: true });
    }
  }, [inView]);
  return (
    <ContactContainer id="contact" ref={ref}>
      <ContactHeadingContainer>
        <ContactHiddenHeading fadeIn={isTransitioned}>FeelFree to</ContactHiddenHeading>
        <ContactButton isTransitioned={isTransitioned} onClick={handleSetTransitioned}>
          <h1>Contact</h1>
          <h1>Me.</h1>
          <ChevronContainer>
            <Chevron />
          </ChevronContainer>
        </ContactButton>
      </ContactHeadingContainer>
      <ContactContent isTranslated={isTransitioned}>
        <ContactForm isTransitioned={isTransitioned} />
        <ContactSources />
        <HelloWorld />
      </ContactContent>
    </ContactContainer>
  );
};
