import { Colors } from 'src/styles/colors';
import styled from 'styled-components';

export const NotFoundContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${Colors.PAPER_WHITE};
  cursor: default;
  display: flex;
  align-items: center;
`;

export const NotFoundHeadingContainer = styled.div`
  padding: 0 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    margin: 0;
    font-family: 'Bebas Neue';
    font-size: 10rem;
    color: ${Colors.ASH_BLACK};
    opacity: 0;
    animation: notFoundFadeIn 1.5s linear forwards;
  }

  @keyframes notFoundFadeIn {
    100% {
      opacity: 1;
    }
  }
`;

export const NotFoundReturnContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  position: relative;
  opacity: 0;
  animation: notFoundFadeIn 1.5s linear forwards;
  animation-delay: 1s;

  &:before {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    border: 3px solid transparent;
    box-sizing: border-box;
    bottom: 0;
    left: -10%;
  }
  &:hover:before {
    height: 110%;
    width: 110%;
    border: 4px solid rgba(0, 0, 0, 0.75);
    border-radius: 3px;
    border-right: none;
    border-bottom: none;
    transition: height 250ms linear, width 200ms linear 250ms;
  }
  &:after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    border: 3px solid transparent;
    box-sizing: border-box;
    top: -10%;
    right: 0;
    transform: scaleX(0);
  }
  &:hover:after {
    transform: scaleX(1);
    height: 110%;
    width: 110%;
    border: 4px solid rgba(0, 0, 0, 0.75);
    border-radius: 3px;
    border-left: none;
    border-top: none;
    transition: height 250ms linear, width 250ms linear 250ms;
  }
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    z-index: 1;
  }
  p {
    margin: 0;
    font-family: 'Bebas Neue';
    font-size: 8rem;
    color: ${Colors.ASH_BLACK};
  }
  svg {
    height: 8rem;
    width: 8rem;
    color: ${Colors.ASH_BLACK};
  }
`;
