import { Colors } from 'src/styles/colors';
import styled from 'styled-components';

export const SkillsContainer = styled.div`
  height: 100vh;
  scroll-snap-align: start end;
  scroll-snap-stop: always;
  background-color: ${Colors.GUNMETAL_GREY};
  padding: 4rem 5rem;
  box-sizing: border-box;
  cursor: default;
  h1 {
    font-family: 'Bebas Neue';
    margin: 0;
    font-size: 3rem;
    color: ${Colors.WHITE};
  }
`;

export const SkillsContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-height: 65vh;
  gap: 1rem;
  margin-top: 1rem;
`;

export const SkillsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  li {
    font-family: 'Calistoga';
    font-size: 2rem;
    color: ${Colors.WHITE};
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-right: 2rem;
    svg {
      width: 3rem;
      height: 3rem;
    }
  }
  @media only screen and (max-width: 560px) {
    & {
      flex-wrap: nowrap;
    }
  }
`;

export const SkillsDescription = styled.div`
  margin-top: -4rem;
  max-width: 60vh;
  min-width: 10rem;
  div {
    margin-bottom: 1rem;
  }
  p {
    font-family: 'Spartan';
    font-size: 1.25rem;
    color: ${Colors.WHITE};
    margin: 0 0 1.5rem 0;
    text-align: right;
    line-height: 3rem;
  }
`;

export const WavesContainer = styled.div`
  height: 15rem;
  position: absolute;
  bottom: 0;
  @media only screen and (max-width: 560px) {
    & {
      height: 10rem;
    }
  }
`;
