import styled, { css } from 'styled-components';
import { Colors } from 'src/styles/colors';
import { ReactComponent as ChevronRight } from '../../assets/svg/chevron-right.svg';

export const ContactContainer = styled.div`
  height: 100vh;
  background-color: #cf9e90;
  scroll-snap-align: start end;
  scroll-snap-stop: always;
  padding: 4rem 5rem;
  box-sizing: border-box;
  cursor: default;
  h1 {
    font-family: 'Bebas Neue';
    font-size: 3rem;
    margin: 0;
    transition: transform 300ms ease;
  }
`;

export const ContactButton = styled.button<{ isTransitioned: boolean }>`
  padding: 0;
  border: none;
  background: transparent;
  display: flex;
  cursor: pointer;
  transition: transform 500ms ease;
  h1:nth-child(2) {
    opacity: 0;
    transition: opacity 500ms ease;
  }
  svg {
    transform: translateX(-100%);
    transition: transform rotate 500ms ease;
  }

  &:hover {
    h1 {
      color: ${Colors.WHITE};
    }
    svg {
      color: ${Colors.WHITE};
    }
  }

  ${({ isTransitioned }) =>
    isTransitioned &&
    css`
      transform: translateY(100%);
      h1:nth-child(2) {
        opacity: 1;
        transition-delay: 500ms;
      }
      svg {
        transform: translateX(0) rotate(180deg);
        transition-delay: 500ms;
      }
      &:hover {
        h1,
        svg {
          color: ${Colors.WHITE};
        }
      }
    `}
  @media only screen and (max-width: 500px) {
    & {
      cursor: default;
      &:hover {
        h1,
        svg {
          color: initial;
        }
      }
    }
  }
`;

export const ChevronContainer = styled.div`
  height: 4rem;
  @media only screen and (max-width: 500px) {
    & {
      display: none;
    }
  }
`;

export const Chevron = styled(ChevronRight)`
  height: 3.25rem;
  width: 3rem;
  transition: transform 300ms ease;
`;

export const ContactHeadingContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
`;

export const ContactHiddenHeading = styled.h1<{ fadeIn: boolean }>`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  ${({ fadeIn }) =>
    fadeIn &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export const ContactContent = styled.div<{ isTranslated: boolean }>`
  display: flex;
  width: 150vw;
  height: 100%;
  gap: 2%;
  transform: ${({ isTranslated }) => (isTranslated ? 'translateX(0)' : 'translateX(-34%)')};
  transition: transform 300ms ease;
  padding-bottom: 5rem;
  box-sizing: border-box;

  @media only screen and (max-width: 500px) {
    width: 100%;
    & {
      gap: 2rem;
      flex-direction: column-reverse;
      transform: initial;
    }
  }
`;
