import { useState, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import {
  SignatureLink,
  WelcomeContainer,
  WelcomePanelLeft,
  WelcomePanelRight,
} from './Welcome.styles';

interface WelcomeProps {
  handleOpening: () => void;
}

export const Welcome = ({ handleOpening }: WelcomeProps) => {
  const { pathname } = useLocation();
  const [sliders, setSliders] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const switchClick = new Audio('/sounds/switch-click.mp3');

  const handlePress = () => {
    setIsPressed(true);
    switchClick.play();
  };

  return (
    <WelcomeContainer>
      <SignatureLink
        to={pathname}
        $isPressed={isPressed}
        onMouseDown={() => handlePress()}
        onMouseUp={() => {
          setSliders(true);
          setIsPressed(false);
          handleOpening();
        }}
        onMouseLeave={() => setIsPressed(false)}
      >
        <button>
          <h1>張</h1>
        </button>
      </SignatureLink>

      {sliders && (
        <Fragment>
          <WelcomePanelLeft />
          <WelcomePanelRight />
        </Fragment>
      )}
    </WelcomeContainer>
  );
};
