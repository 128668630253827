import styled, { css } from 'styled-components';

export const RainOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const Raindrop = styled.div<{
  left: number;
  top: number;
  delay?: number;
  height?: number;
}>`
  transform: rotate(10deg);
  width: 1px;
  height: ${({ height }) => (height ? `${height}px` : '90px')};
  position: absolute;
  bottom: 200px;
  left: ${({ left }) => `${left}px`};
  top: ${({ top }) => `${top}px`};
  animation: fall 2s linear infinite;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.9) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.9) 100%);
  z-index: 1;

  ${({ delay }) =>
    delay &&
    css`
      opacity: 0;
      animation: fall 3s linear infinite;
      animation-delay: ${delay}s;
    `}

  @keyframes fall {
    to {
      opacity: 1;
      transform: translateY(200vh) rotate(10deg);
    }
  }
`;
