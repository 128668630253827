export const scrollToSection = (sectionId: string) => {
  const section = document.querySelector(sectionId) as HTMLElement;
  const offsetTop = section?.offsetTop;
  const main = document.querySelector('main');
  main?.scrollTo({ top: offsetTop, behavior: 'smooth' });
};

export const validateEmail = (email: string) => {
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const match = email.match(emailRegex);
  if (!match) return false;
  return match[0] === email;
};
