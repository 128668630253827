import { HomeContainer } from './Home.styles';
import { Contact } from 'src/components/Contact/Contact';
import { Hero } from 'src/components/Hero/Hero';
import { Profile } from 'src/components/Profile/Profile';
import { Skills } from 'src/components/Skills/Skills';

export const Home = () => {
  return (
    <HomeContainer>
      <Hero />
      <Profile />
      <Skills />
      <Contact />
    </HomeContainer>
  );
};
