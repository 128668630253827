import { ReactComponent as ChevronRight } from '../../assets/svg/chevron-right.svg';
import { HashLink } from 'react-router-hash-link';
import {
  NotFoundContainer,
  NotFoundHeadingContainer,
  NotFoundReturnContainer,
} from './NotFound.styles';

export const NotFound = () => {
  return (
    <NotFoundContainer>
      <NotFoundHeadingContainer>
        <h1>404 // Not Found</h1>
        <NotFoundReturnContainer>
          <HashLink to="/#home">
            <p>Return</p>
            <ChevronRight />
          </HashLink>
        </NotFoundReturnContainer>
      </NotFoundHeadingContainer>
    </NotFoundContainer>
  );
};
