import { Link } from 'react-router-dom';
import { Colors } from 'src/styles/colors';
import styled, { css } from 'styled-components';

export const WelcomeContainer = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  background: linear-gradient(90deg, rgb(218, 222, 235) 0%, rgba(218, 233, 255, 0.9) 100%);
  overflow: hidden;
  font-family: 'Noto Sans TC';
  position: absolute;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const SignatureLink = styled(Link)<{ $isPressed: boolean }>`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: auto 0;
  position: absolute;
  height: 15rem;
  width: 15rem;
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  border: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  ${({ $isPressed }) =>
    $isPressed &&
    css`
      box-shadow: inset 12px 12px 16px 0 rgba(0, 0, 0, 0.2),
        inset -8px -8px 12px 0 rgba(255, 255, 255, 0.5);
    `}
  h1 {
    font-size: 12rem;
    line-height: 12rem;
    position: relative;
    color: ${Colors.ASH_BLACK};
    margin: 0;
    font-family: 'Noto Sans TC', sans-serif;
    bottom: 6px;
  }
  button {
    background: none;
    border: none;
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
  }
  @media only screen and (max-width: 500px) {
    height: 18rem;
    width: 18rem;
    h1 {
      font-size: 14rem;
      line-height: 14rem;
    }
  }
`;

export const WelcomePanelLeft = styled.div`
  background-color: ${Colors.ASH_BLACK};
  width: 50%;
  animation-name: slideFromLeft;
  visibility: hidden;
  height: 100vh;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  @keyframes slideFromLeft {
    0% {
      transform: translateX(-150%);
      visibility: visible;
    }
    100% {
      transform: translateX(0);
      visibility: visible;
    }
  }
`;
export const WelcomePanelRight = styled.div`
  background-color: ${Colors.ASH_BLACK};
  width: 50%;
  animation-name: slideFromRight;
  visibility: hidden;
  height: 100vh;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  @keyframes slideFromRight {
    0% {
      transform: translateX(150%);
      visibility: visible;
    }
    100% {
      transform: translateX(0);
      visibility: visible;
    }
  }
`;
