import {
  SuccessContainer,
  SuccessHeadingContainer,
  SuccessReturnContainer,
} from './Success.styles';
import { ReactComponent as ChevronRight } from '../../assets/svg/chevron-right.svg';
import { HashLink } from 'react-router-hash-link';

export const Success = () => {
  return (
    <SuccessContainer>
      <SuccessHeadingContainer>
        <h1>Success</h1>
        <SuccessReturnContainer>
          <HashLink to="/#contact">
            <p>Return</p>
            <ChevronRight />
          </HashLink>
        </SuccessReturnContainer>
      </SuccessHeadingContainer>
    </SuccessContainer>
  );
};
