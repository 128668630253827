import { useState } from 'react';
import { Home } from './pages/Home/Home';
import { Welcome } from './components/Welcome/Welcome';
import { AppContainer } from './App.styles';
import { Route, Routes, useLocation } from 'react-router';
import { Success } from './components/Success/Success';
import { NotFound } from './components/NotFound/NotFound';

const App = () => {
  const { pathname, hash } = useLocation();
  const showWelcomeBoolean = hash === '#home' || (pathname === '/' && hash === '');
  const [showWelcome, setShowWelcome] = useState(showWelcomeBoolean);

  const handleOpening = () => {
    setTimeout(() => setShowWelcome(false), 3000);
  };

  return (
    <AppContainer>
      <Routes>
        <Route
          path="/"
          element={showWelcome ? <Welcome handleOpening={handleOpening} /> : <Home />}
        />
        <Route path="/success" element={<Success />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AppContainer>
  );
};

export default App;
