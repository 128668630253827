import { useWindowDimensions } from 'src/hooks/useWindowDimensions';
import { RainOverlay, Raindrop } from './Rain.styles';

export const Rain = () => {
  const { width, height } = useWindowDimensions();
  const randomRange = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const raindropCount = 10;
  const raindropsLayerOne: Array<{ left: number; top: number }> = [];
  const raindropsLayerTwo: Array<{ left: number; top: number }> = [];
  const raindropsLayerThree: Array<{ left: number; top: number }> = [];

  for (let i = 1; i < raindropCount; i++) {
    const left = randomRange(0, width);
    const top = randomRange(height * -1, 0);

    raindropsLayerOne.push({ left, top });
  }

  for (let i = 1; i < raindropCount; i++) {
    const left = randomRange(0, width);
    const top = randomRange(height * -1, 0);

    raindropsLayerTwo.push({ left, top });
  }

  for (let i = 1; i < raindropCount; i++) {
    const left = randomRange(0, width);
    const top = randomRange(height * -1, 0 / 2);

    raindropsLayerThree.push({ left, top });
  }

  return (
    <RainOverlay>
      {raindropsLayerOne.map(({ left, top }) => (
        <Raindrop key={`${left}-${top}`} left={left} top={top} />
      ))}
      {raindropsLayerTwo.map(({ left, top }) => (
        <Raindrop key={`${left}-${top}`} left={left} top={top} delay={1} height={60} />
      ))}
      {raindropsLayerThree.map(({ left, top }) => (
        <Raindrop key={`${left}-${top}`} left={left} top={top} delay={2} height={40} />
      ))}
    </RainOverlay>
  );
};
