import styled from 'styled-components';
import { ReactComponent as ChevronRight } from '../../assets/svg/chevron-right.svg';
import { Colors } from 'src/styles/colors';

export const ChevronDown = styled(ChevronRight)`
  cursor: pointer;
  width: 5rem;
  height: 5rem;
  color: ${Colors.PAPER_WHITE};
  margin: 0 auto;
  transform: rotate(90deg);
  opacity: 0;
`;

export const ChevronDownWhite = styled(ChevronDown)`
  color: ${Colors.PAPER_WHITE};
`;

export const ChevronDownBlack = styled(ChevronDown)`
  color: ${Colors.ASH_BLACK};
`;

export const SectionScrollDown = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  display: grid;
  place-items: center;
  &:hover {
    ${ChevronDown} {
      opacity: 0.7;
      transition: opacity 1000ms ease;
      transition-delay: 1000ms;
      animation: chevronSlideDown 750ms linear infinite;
      animation-play-state: running;
    }
  }

  @keyframes chevronSlideDown {
    100% {
      transform: translateY(40%) rotate(90deg);
    }
  }
`;
