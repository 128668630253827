import styled from 'styled-components';
import { Colors } from 'src/styles/colors';
import gate from '../../assets/images/gate.jpg';

export const HeroContainer = styled.section`
  position: relative;
  width: 100vw;
  height: 100vh;
  font-family: 'Bebas Neue', Lucida, sans-serif;
  scroll-snap-align: start end;
`;

export const HeroCover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.ASH_BLACK};
  height: 100vh;
  opacity: 0.98;
`;

export const HeroImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: url(${gate});
  background-repeat: repeat;
  background-position: 40% 50%;
  -webkit-background-clip: text;
  background-clip: text;
  -ms-background-clip: text;
  @media only screen and (max-width: 500px) {
    background-size: cover;
  }
`;

export const HeroText = styled.h1`
  color: transparent;
  font-family: 'Bebas Neue', Lucida, sans-serif;
  margin: 0;
  font-size: 12rem;
  letter-spacing: -4rem;
  animation-name: expand;
  animation-duration: 2s;
  animation-fill-mode: forwards;

  @keyframes expand {
    0% {
      letter-spacing: -4rem;
    }
    100% {
      letter-spacing: 0;
    }
  }
`;
