import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useMousePosition from 'src/hooks/useMousePosition';
import { scrollToSection } from 'src/utils/utils';
import { useInView } from 'react-intersection-observer';
import { ChevronDownWhite, SectionScrollDown } from '../UI/ui.styles';
import { HeroContainer, HeroCover, HeroImage, HeroText } from './Hero.styles';

export const Hero = () => {
  const { ref, inView } = useInView({ threshold: 1, initialInView: true });
  const navigate = useNavigate();
  const { callbackRef, mousePosition } = useMousePosition();

  const isDesktop = window.innerWidth > 500

  useEffect(() => {
    if (inView) {
      navigate('/#home', { replace: true });
    }
  }, [inView]);
  return (
    <HeroContainer id="home">
      <HeroCover>
        <HeroImage
          ref={callbackRef}
          style={{
            backgroundPosition: isDesktop ? `${(4 * mousePosition.left) / 570 + 40}% ${
              (4 * mousePosition.top) / 570 + 50
            }%`: 'center',
          }}
        >
          <HeroText ref={ref}>Eric SK.C</HeroText>
        </HeroImage>
      </HeroCover>
      <SectionScrollDown onClick={() => scrollToSection('#profile')}>
        <ChevronDownWhite />
      </SectionScrollDown>
    </HeroContainer>
  );
};
