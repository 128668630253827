import { useState } from 'react';
import {
  ContactFormContainer,
  DisableCaptcha,
  EmailSuccess,
  Honeypot,
  StyledContactForm,
} from './ContactForm.styles';
import { validateEmail } from 'src/utils/utils';

interface ContactFormProps {
  isTransitioned: boolean;
}

export const ContactForm = ({ isTransitioned }: ContactFormProps) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const isDisabled = !name.length || !email.length || !message.length || !validateEmail(email);
  return (
    <ContactFormContainer isTransitioned={isTransitioned}>
      <StyledContactForm
        action="https://formsubmit.co/1a51cc97eeb89c3b9b86daafc350b9b9"
        method="POST"
        onSubmit={(e) => e.preventDefault}
        isDisabled={isDisabled}
      >
        <Honeypot />
        <DisableCaptcha />
        <EmailSuccess />

        <div>
          <label htmlFor="name">your name</label>
          <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div>
          <label htmlFor="email">your email</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="message">your message</label>
          <textarea
            cols={40}
            rows={10}
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        {isDisabled ? (
          <button title="Please check name, email, and message are correct" disabled={isDisabled}>
            Send
          </button>
        ) : (
          <button type="submit" disabled={isDisabled}>
            Send
          </button>
        )}
      </StyledContactForm>
    </ContactFormContainer>
  );
};
