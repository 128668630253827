import { Colors } from 'src/styles/colors';
import styled from 'styled-components';

export const ProfileHeader = styled.div`
  flex: 1;
  margin-bottom: 2rem;
  p {
    font-family: 'Calistoga';
    font-size: 1.25rem;
  }
`;

export const ProfileBio = styled.div`
  flex: 2;
  p {
    font-family: 'Spartan';
    letter-spacing: 1px;
    font-size: 1.25rem;
    line-height: 3rem;
  }
`;

export const ProfileContainer = styled.div`
  height: 100vh;
  scroll-snap-align: start end;
  scroll-snap-stop: always;
  background-color: ${Colors.PAPER_WHITE};
  padding: 4rem 5rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  cursor: default;
  h1 {
    font-family: 'Bebas Neue';
    text-transform: uppercase;
    font-size: 4rem;
    margin-bottom: 1rem;
    margin-top: 0;
  }
  h2 {
    font-family: 'Bebas Neue';
    text-transform: uppercase;
    font-size: 2.5rem;
    margin: 0;
  }
`;
