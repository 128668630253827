import { Container } from 'src/pages/Home/Home.styles';
import { Rain } from '../Animations/Rain/Rain';
import { ProfileBio, ProfileContainer, ProfileHeader } from './Profile.styles';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { ChevronDownBlack, SectionScrollDown } from '../UI/ui.styles';
import { scrollToSection } from 'src/utils/utils';

export const Profile = () => {
  const { ref, inView } = useInView({ threshold: 1 });
  const navigate = useNavigate();

  useEffect(() => {
    if (inView) {
      navigate('/#profile', { replace: true });
    }
  }, [inView]);
  return (
    <Container id="profile">
      <Rain />
      <ProfileContainer ref={ref}>
        <ProfileHeader>
          <h1>Eric Sai Kit Cheung.</h1>
          <h2>Software Engineer | Full Stack Developer</h2>
          <p>an aesthetic fanatic,</p>
          <p>compulsive problem solver,</p>
          <p>and lifetime student.</p>
        </ProfileHeader>
        <ProfileBio>
          <h2>WHOAMI.</h2>
          <p>
            I travel, fall asleep in sunbeams, and have made it my life&apos;s goal to eat as many
            varieties of food as possible. A Brooklyn native, I&apos;ve lived in Philadelphia,
            Southern California, Hawaii, and Tokyo.
          </p>
          <p>
            I&apos;ve made Jason Sudeikis laugh, owned a food business in Brooklyn & Hawaii, and
            believe Futurama is a perfect show.
          </p>
        </ProfileBio>
      </ProfileContainer>
      <SectionScrollDown onClick={() => scrollToSection('#skills')}>
        <ChevronDownBlack />
      </SectionScrollDown>
    </Container>
  );
};
