import styled, { css } from 'styled-components';
import { Colors } from 'src/styles/colors';

export const ContactFormContainer = styled.div<{ isTransitioned: boolean }>`
  flex: 1;
  margin-top: 5rem;
  opacity: 0;
  transition: opacity 300ms ease;

  ${({ isTransitioned }) =>
    isTransitioned &&
    css`
      opacity: 1;
    `}

  @media only screen and (max-width: 500px) {
    & {
      opacity: 1;
      margin-top: 0;
    }
  }
`;

export const StyledContactForm = styled.form<{ isDisabled: boolean }>`
  font-family: 'Calistoga';
  font-size: 1.25rem;
  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  label {
    margin-bottom: 0.5rem;
  }
  input {
    height: 2rem;
    padding: 0.5rem 1rem;
  }
  textarea {
    padding: 1rem;
  }
  input,
  textarea {
    outline: none;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.6);
    box-shadow: inset 0 1px 2px 0px rgba(0, 0, 0, 0.1);
    resize: vertical;
    max-height: 40vh;
  }
  button {
    font-family: 'Calistoga';
    font-size: 2rem;
    width: 10rem;
    height: 4rem;
    background-color: ${Colors.ASH_BLACK};
    color: ${Colors.PAPER_WHITE};
    border-radius: 5px;
    border: none;
    cursor: pointer;
    float: right;
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        pointer-events: none;
        opacity: 0.5;
      `}
  }
`;

// Form submit usage
// Honeypot for spammers
export const Honeypot = styled.input.attrs({ type: 'text', name: '_honey' })`
  display: none;
`;
export const DisableCaptcha = styled.input.attrs({
  type: 'hidden',
  name: '_captcha',
  value: 'false',
})``;
// Route Success Page
export const EmailSuccess = styled.input.attrs({
  type: 'hidden',
  name: '_next',
  value: `${process.env.REACT_APP_EMAIL_URL}/success`,
})``;
