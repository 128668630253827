import styled from 'styled-components';
import { Colors } from 'src/styles/colors';

export const ContactListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContactList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  &:hover > li:not(:hover) {
    transition: filter 300ms ease;
    filter: grayscale(50%) sepia(30%);
  }
`;

export const ContactListItem = styled.li`
  cursor: pointer;
  flex: 1;
  background: ${Colors.WHITE};
  position: relative;
  a {
    color: ${Colors.BLACK};
    text-decoration: none;
    font-family: 'Calistoga';
    font-size: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  div {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 100%;
      left: 0;
      opacity: 0.75;
      background-color: ${Colors.ASH_BLACK};
      transition: width 750ms ease;
      z-index: 1;
    }
  }
  p {
    z-index: 1;
    margin: 0;
    color: ${Colors.WHITE};
  }
  svg {
    height: 3rem;
    width: 3rem;
    z-index: 1;
    opacity: 0;
    transition: opacity 500ms ease;
    color: ${Colors.WHITE};
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: 40% 30%;
    bottom: 0;
    filter: brightness(85%);
    transition: filter 500ms ease;
    -webkit-transition : -webkit-filter 500ms linear
    -moz-transition : -moz-filter 500ms linear
  }
  &:after {
    height: 0;
    width: 100%;
    background-color: ${Colors.WHITE};
  }
  &:hover {
    div:before {
      width: 100%;
    }
    svg {
      opacity: 1;
    }
    img {
      filter: contrast(140%) brightness(100%);
    }
  }
`;
