import { Colors } from 'src/styles/colors';
import styled from 'styled-components';

export const HomeContainer = styled.main`
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    padding: 0 1px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${Colors.ASH_BLACK};
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  ::-moz-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 480px) {
    ::-webkit-scrollbar {
      display: none;
    }
    & {
      scroll-snap-type: proximity;
    }
  }
`;

export const Container = styled.section`
  height: 100vh;
  position: relative;
`;
