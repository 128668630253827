import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { Container } from 'src/pages/Home/Home.styles';
import { Wave } from '../Animations/Wave/Wave';
import {
  SkillsContainer,
  SkillsContent,
  SkillsDescription,
  SkillsList,
  WavesContainer,
} from './Skills.styles';
import { ReactComponent as ReactIcon } from '../../assets/svg/react.svg';
import { ReactComponent as TypescriptIcon } from '../../assets/svg/typescript.svg';
import { ReactComponent as JavascriptIcon } from '../../assets/svg/javascript.svg';
import { ReactComponent as NextIcon } from '../../assets/svg/nextjs.svg';
import { ReactComponent as RubyIcon } from '../../assets/svg/icons8-ruby-programming-language.svg';
import { ReactComponent as RailsIcon } from '../../assets/svg/rails.svg';
import { ReactComponent as PythonIcon } from '../../assets/svg/python.svg';
import { ReactComponent as NodeIcon } from '../../assets/svg/node.svg';
import { ReactComponent as HTMLIcon } from '../../assets/svg/html.svg';
import { ReactComponent as CSSIcon } from '../../assets/svg/css.svg';
import { ReactComponent as SASSIcon } from '../../assets/svg/sass.svg';
import { ReactComponent as PostgreSQLIcon } from '../../assets/svg/icons8-postgresql.svg';
import { ReactComponent as FigmaIcon } from '../../assets/svg/figma.svg';
import { ChevronDownBlack, SectionScrollDown } from '../UI/ui.styles';
import { scrollToSection } from 'src/utils/utils';

export const Skills = () => {
  const { ref, inView } = useInView({ threshold: 1 });
  const navigate = useNavigate();

  useEffect(() => {
    if (inView) {
      navigate('/#skills', { replace: true });
    }
  }, [inView]);
  return (
    <Container id="skills">
      <SkillsContainer ref={ref}>
        <h1>Skills & Tools.</h1>
        <SkillsContent>
          <SkillsList>
            <li>
              <TypescriptIcon />
              Typescript
            </li>
            <li>
              <JavascriptIcon />
              Javascript
            </li>
            <li>
              <ReactIcon />
              React
            </li>
            <li>
              <NextIcon />
              NextJS
            </li>
            <li>
              <RubyIcon />
              Ruby
            </li>
            <li>
              <RailsIcon />
              Rails
            </li>
            <li>
              <PythonIcon />
              Python
            </li>
            <li>
              <NodeIcon />
              Node
            </li>
            <li>
              <HTMLIcon />
              HTML
            </li>
            <li>
              <CSSIcon />
              CSS
            </li>
            <li>
              <SASSIcon />
              SASS
            </li>
            <li>
              <PostgreSQLIcon />
              PostgreSQL
            </li>
            <li>
              <FigmaIcon />
              Figma
            </li>
          </SkillsList>
          <SkillsDescription>
            <div>
              <p>My work aims to be creative yet functional, intuitive yet sophisticated.</p>
            </div>
            <div>
              <p>
                Professional Full stack developer, but Frontend connoisseur at heart.
              </p>
            </div>
            <div>
              <p>
                Technology keeps progressing, and I&apos;ll be spending my time exploring it.
              </p>
            </div>
          </SkillsDescription>
        </SkillsContent>
      </SkillsContainer>
      <WavesContainer>
        <Wave />
      </WavesContainer>
      <SectionScrollDown onClick={() => scrollToSection('#contact')}>
        <ChevronDownBlack />
      </SectionScrollDown>
    </Container>
  );
};
